import { TradePriceInfo } from '@/types'
import { MappedExchangeRates } from 'api/exchange-rate'
import { UserProfile } from 'api/profiles'
import { GetIPInfoRes } from 'api/users'
import { Currencies, Currency } from 'constants/currencies'

export function isValidCurrency(currency: unknown): currency is Currency {
  if (typeof currency !== 'string') return false

  const validValues = Object.values(Currencies) as string[]

  return validValues.includes(currency)
}

export interface ConvertPayload {
  /**
   * The currency which the value should be converted from. Defaults to GBP.
   */
  from?: Currency | null
  /**
   * The currency which the value should be converted to. Defaults to user currency.
   */
  to?: Currency | null
}

function isUSA(country?: string | null) {
  return country?.toLowerCase() === 'us' || country?.toLowerCase() === 'usa'
}

/**
 * Based on the user data, determines the preferred currency.
 */
export function determineUserCurrency(
  userProfile: UserProfile | null | undefined,
  ipInfo?: GetIPInfoRes | null
) {
  if (userProfile?.shippingAddress?.country) {
    if (isUSA(userProfile?.shippingAddress?.country)) return Currencies.USD
    // if the user has shipping country set, but it's not USA, display prices in GBP
    return Currencies.GBP
  }
  if (userProfile?.countryIp) {
    if (isUSA(userProfile?.countryIp)) return Currencies.USD
  }

  if (isUSA(ipInfo?.country)) {
    return Currencies.USD
  }

  return Currencies.GBP
}

export interface PickExchangeRatePayload extends Required<ConvertPayload> {
  exchange: MappedExchangeRates | undefined | null
}

export function pickExchangeRate({
  exchange,
  from,
  to,
}: PickExchangeRatePayload) {
  // if we can't determine the exchange rate, we don't convert it
  if (!exchange) {
    return 1
  }

  // if both values are the same, they shouldn't be converted
  if (from === to) {
    return 1
  }

  switch (from) {
    case Currencies.GBP:
      switch (to) {
        case Currencies.GBP:
          return 1
        case Currencies.USD:
          return exchange.gbpToUsd
      }
      break

    case Currencies.USD:
      switch (to) {
        case Currencies.GBP:
          return exchange.usdToGbp
        case Currencies.USD:
          return 1
      }
      break
  }

  return 1
}

export interface FallbackCurrenciesPayload extends ConvertPayload {
  userProfile: UserProfile | undefined | null
  ipInfo: GetIPInfoRes | undefined | null
}

export function fallbackCurrencies(payload: FallbackCurrenciesPayload) {
  const userCurrency = determineUserCurrency(
    payload.userProfile,
    payload.ipInfo
  )

  // default the source currency to GBP, as it was before adding support for multiple currencies
  let from: Currency = Currencies.GBP
  if (isValidCurrency(payload.from)) {
    if (payload.from === Currencies.USER) {
      from = userCurrency
    } else {
      from = payload.from
    }
  }

  let to = userCurrency
  if (isValidCurrency(payload.to) && payload.to !== Currencies.USER) {
    to = payload.to
  }

  return { from, to }
}

export interface ConvertValuePayload extends ConvertPayload {
  exchange: MappedExchangeRates | undefined | null
  userProfile: UserProfile | undefined | null
  ipInfo: GetIPInfoRes | undefined | null
}

/**
 * Converts a price from one currency to another. In case there are no values provided for `from` or `to`, uses fallback values.
 */
export function convertValue(
  value: number | undefined,
  payload: ConvertValuePayload
) {
  const { exchange } = payload
  const { from, to } = fallbackCurrencies(payload)

  // if the currency we want to convert from and the one we want to convert to are the same, we don't need to convert anything
  if (value === undefined || from === to) {
    return value
  }

  const rate = pickExchangeRate({ exchange, from, to })

  if (!rate) {
    return value
  }

  // we want to round it to 2 decimals
  return convertValueWithRate(value, rate)
}

export function roundToTwoDecimals(value: number | undefined) {
  if (!value) return 0

  return Math.round(value * 100) / 100
}

export function convertValueWithRate(value: number | undefined, rate: number) {
  if (!value) return 0

  // we want to round it to 2 decimals
  return roundToTwoDecimals(value * rate)
}

export function getCurrencySymbol(currency: Currency) {
  switch (currency) {
    case Currencies.USD:
      return '$'
    case Currencies.GBP:
    default:
      return '£'
  }
}

interface PickPricePayload {
  userCurrency: Currency
  /**
   * The currency the of the base price.
   */
  priceCurrency: Currency | undefined | null
  price: number | undefined | null
  /**
   * The price converted in GBP from other currency.
   */
  priceGbp: number | undefined | null
  /**
   * The price converted in USD from other currency.
   */
  priceUsd: number | undefined | null
}

/**
 * Based on the user's currency and the currency of the price, determines which price should be shown to the user.
 */
export function pickPrice(payload: PickPricePayload) {
  const { price, priceCurrency, priceGbp, priceUsd, userCurrency } = payload
  if (userCurrency === priceCurrency) {
    return price
  }

  if (
    userCurrency === Currencies.GBP &&
    priceCurrency === Currencies.USD &&
    priceGbp
  ) {
    return priceGbp
  }

  if (
    userCurrency === Currencies.USD &&
    priceCurrency === Currencies.GBP &&
    priceUsd
  ) {
    return priceUsd
  }
  return price
}

/**
 * Looks at the user's currency and the data regarding the pricing of the trade and picks an appropiate price which should be used.
 */
export function pickTradePrice(
  userCurrency: Currency,
  tradePrices: TradePriceInfo | undefined | null
) {
  return pickPrice({
    userCurrency,
    priceCurrency: tradePrices?.currency,
    price: tradePrices?.price,
    priceGbp: tradePrices?.priceConvertedToGbp,
    priceUsd: tradePrices?.priceConvertedToUsd,
  })
}
